
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  unref,
} from "vue";
import { useStore } from "vuex";
import type { FormInstance } from "element-plus";
import { changeUserInfo } from "@/api/login";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { gaEvent } from "@/utils/format";

export default defineComponent({
  name: "ChangeName",
  components: {},

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "user.changename",
    },
  },
  emits: ["saveName"],
  setup(props, context) {
    const { t } = useI18n();

    const store = useStore();
    const visible = computed(() => props.visible);
    const username = ref();
    const lang = computed(() => store.state.currentLang);
    const getusername = computed(() => {
      return store.state.userInfo.name;
    });
    const formData = ref({
      username: getusername.value,
      inputUserName: false,
    });
    onMounted(() => {
      formData.value = {
        username: getusername.value,
        inputUserName: false,
      };
    });

    // username.value = getusername.value
    // const f
    const rule = reactive({
      username: [
        {
          required: true,
          message: t("user.nameNull"),
          trigger: "blur",
        },
        {
          required: true,
          pattern: /^.{0,50}$/,
          message: t("user.nameNull"),
          trigger: "blur",
        },
      ],
    });
    const changeFormRef = ref<FormInstance>();
    let checkUserName = () => {
      const formRef: any = unref(changeFormRef);
      formRef.validateField("username", (valid: boolean) => {
        if (valid) {
          formData.value.inputUserName = true;
          return true;
        } else {
          formData.value.inputUserName = false;
          return false;
        }
      });
    };

    const handleClose = () => {
      context.emit("saveName", username);
    };

    const clearAll = () => {
      formData.value.username = "";
    };

    const saveName = () => {
      let params = {
        username: formData.value.username,
      };
      // 如果校验不通过不发送网络请求
      if (!formData.value.inputUserName) {
        context.emit("saveName", username);
        return;
      }
      changeUserInfo(params)
        .then((res) => {
          if (res.data.code == 200 && res.data.message == "OK") {
            store.dispatch("getUserInfo");
            ElMessage({
              message: t("user.nameSuccess"),
              type: "success",
            });
          }
        })
        .catch((err) => {
          ElMessage({
            message: t("user.networkError"),
            type: "error",
          });
        });
      context.emit("saveName", username);
    };
    return {
      lang,
      formData,
      visible,
      rule,
      changeFormRef,
      clearAll,
      handleClose,
      saveName,
      checkUserName,
    };
  },
});
