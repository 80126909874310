
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  computed,
  ref,
  onMounted,
  watch,
  nextTick,
} from "vue";
import MENU from "./menu.vue";
import emitter from "@/utils/eventBus";
import { gaEvent } from "@/utils/format";

export default defineComponent({
  setup() {
    const menuRef = ref();
    const store = useStore();
    const getMenuList = computed(() => store.state.menuList2);

    // 当刷新的时候，下拉菜单会关闭，这时候让单个的设备展示
    onMounted(() => {
      store.commit("setSingleDeviceShow", true);
    });

    // watch(
    //   () => store.state.currentDeviceInfo.id,
    //   (newVal, oldVal) => {
    //     nextTick(() => {
    //       menuRef.value.close('Device')
    //     })
    //     store.commit('setSingleDeviceShow', true)
    //   }
    // )
    let state = reactive({
      isSidebarNavCollapse: computed(
        () => store.getters.getIsSidebarNavCollapse
      ),
      currentDevice: computed(() => {
        return store.state.currentDevice;
      }),
    });
    const menuOpened = (index: any) => {
      if (index === "Device") {
        store.commit("setSingleDeviceShow", false);
      }
    };
    const menuClosed = (index: any) => {
      if (index === "Device") {
        store.commit("setSingleDeviceShow", true);
      }
    };

    emitter.on("closeMenu", () => {
      // console.log("emitter.on");
      menuRef.value?.close("Device");
      store.commit("setSingleDeviceShow", true);
    });

    emitter.on("openMenu", () => {
      menuRef.value?.open("Device");
      store.commit("setSingleDeviceShow", false);
    });

    

    return {
      state,
      menuRef,
      getMenuList,
      menuOpened,
      menuClosed,

    };
  },
  components: {
    MENU,
  },
});
