
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { getAvatarUrl, changeUserInfo } from "@/api/login";
import { useI18n } from "vue-i18n";
import { gaEvent } from "@/utils/format";

export default defineComponent({
  name: "ChangeAvator",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "user.changeavatar",
    },
  },
  emits: ["saveAvatar"],
  setup(props, context) {
    const { t } = useI18n();
    const fileObj = ref({
      file_ext: "",
      file_content_length: "",
      file_content_type: "",
    });
    const svg = `
    <path d="M16.5 31.04C16.5 31.5702 16.0698 32.003 15.5405 31.9712C12.7133 31.8014 9.97542 30.8835 7.61088 29.3035C4.97969 27.5454 2.92893 25.0466 1.71793 22.1229C0.506925 19.1993 0.190072 15.9822 0.807436 12.8786C1.4248 9.77486 2.94865 6.92393 5.18629 4.68629C7.42393 2.44865 10.2749 0.924798 13.3786 0.307435C16.4823 -0.309928 19.6993 0.00692577 22.6229 1.21793C25.5466 2.42893 28.0454 4.47969 29.8035 7.11088C31.3835 9.47542 32.3014 12.2133 32.4712 15.0405C32.503 15.5698 32.0702 16 31.54 16C31.0098 16 30.5834 15.5697 30.5473 15.0407C30.3802 12.5935 29.576 10.2263 28.2071 8.17757C26.66 5.86213 24.461 4.05746 21.8882 2.99178C19.3154 1.92609 16.4844 1.64726 13.7531 2.19054C11.0219 2.73382 8.51306 4.07481 6.54394 6.04394C4.57481 8.01306 3.23382 10.5219 2.69054 13.2531C2.14726 15.9844 2.42609 18.8154 3.49178 21.3882C4.55746 23.961 6.36213 26.16 8.67757 27.7071C10.7263 29.076 13.0935 29.8802 15.5407 30.0473C16.0697 30.0834 16.5 30.5098 16.5 31.04Z" fill="white"/>
    `;
    const bFile = ref();
    const loading = ref(false);
    const bttDisabled = ref(true);
    const maxsize = 2 * 1024 * 1024;
    const store = useStore();
    const visible = computed(() => props.visible);
    const userInfo = computed(() => store.state.userInfo);
    const upload = () => { };
    const tempURL = ref();
    const lang = computed(() => store.state.currentLang);

    // 判断是否有错误发生
    const errorAppend = ref(false);
    const errorText = ref("");

    onMounted(() => {
      tempURL.value = userInfo.value.profile_picture_url;
    });
    // tempURL.value = userInfo
    // 预览选择文件
    const changeFile = (file: any) => {
      // console.log('changeFile')
      const index = file.file.name.lastIndexOf(".");
      const ext = file.file.name.substr(index + 1);
      if (
        ext === "jpg" ||
        ext === "jpeg" ||
        ext === "png" ||
        ext === "JPG" ||
        ext === "JPEG" ||
        ext === "PNG" ||
        ext === "Jpg" ||
        ext === "Jpeg" ||
        ext === "Png"
      ) {
        errorAppend.value = false;
        errorText.value = t("");
      } else {
        errorAppend.value = true;
        errorText.value = t("user.supported");
        ElMessage({
          message: t("user.supported"),
          type: "error",
        });
        return;
      }
      checkFile(file.file);
      let reader = new FileReader();
      if (file.file.size > maxsize) {
        errorAppend.value = true;
        errorText.value = t("user.maxsize");
        ElMessage({
          message: t("user.maxsize"),
          type: "error",
        });
        return;
      }
      // 文件对象转二进制
      reader.readAsArrayBuffer(file.file);
      reader.onload = function (e: any) {
        bFile.value = e.target.result;

        // 将文件对象转化成url链接在上面预览进行显示
        tempURL.value = window.URL.createObjectURL(file.file);
        // console.log('tempURL', tempURL.value)
        bttDisabled.value = false;
      };
    };

    const handleClose = () => {
      context.emit("saveAvatar");
    };

    const save = () => {
      let params = {
        file_ext: fileObj.value.file_ext,
        content_length: fileObj.value.file_content_length,
        content_type: fileObj.value.file_content_type,
        prefix: "profile-picture",
        acl: "public-read",
      };
      bttDisabled.value = true;

      getAvatarUrl(params).then((res) => {
        if (res.data.code === 200 && res.data.message === "OK") {
          let info = res.data.data;
          let oss_key = info.oss_key;

          let url = info.url;
          var myHeaders = new Headers();
          myHeaders.append("X-Oss-Object-Acl", "public-read");
          myHeaders.append("Content-Type", params.content_type);
          var requestOptions: any = {
            method: "PUT",
            headers: myHeaders,
            body: bFile.value,
            redirect: "follow",
          };
          // console.log('requestOptions', requestOptions)
          fetch(url, requestOptions)
            .then(() => {
              // let res = url
            })
            .catch((err) => { })
            .finally(() => {
              let obj = {
                profile_picture_key: oss_key,
              };
              changeUserInfo(obj).then((res) => {
                if (res.data.code === 200 && res.data.message === "OK") {
                  // loading.value = false
                  store.dispatch("getUserInfo");
                  context.emit("saveAvatar");
                  errorAppend.value = true;
                  errorText.value = t("user.avatarSuccess");
                  ElMessage({
                    message: t("user.avatarSuccess"),
                    type: "success",
                  });
                } else {
                  errorAppend.value = true;
                  errorText.value = t("user.avatarError");
                  ElMessage.error(t("user.avatarError"));
                }
              });
            });
        }
      });

      loading.value = true;
      // bttDisabled.value = false
    };

    // 检查文件格式
    const checkFile = async (file: any) => {
      const index = file.name.lastIndexOf(".");
      const ext = file.name.substr(index + 1);
      // console.log('ext:', ext)

      fileObj.value.file_ext = ext;
      fileObj.value.file_content_length = file.size;
      fileObj.value.file_content_type = file.type;
      if (
        ext === "jpg" ||
        ext === "jpeg" ||
        ext === "png" ||
        ext === "JPG" ||
        ext === "JPEG" ||
        ext === "PNG" ||
        ext === "Jpg" ||
        ext === "Jpeg" ||
        ext === "Png"
      ) {
      } else {
        ElMessage({
          message: t("user.imgError"),
          type: "error",
        });
        return false;
      }
    };

    const dragMore = (files: any) => {
      let file = files[0];
      checkFile(file);
    };
    // 当图片损坏时调用
    const errImg = () => {
      errorAppend.value = true;
      errorText.value = t("user.imgError");
      ElMessage({
        message: t("user.imgError"),
        type: "error",
      });
      // 当点击为损坏图片时默认选择当前用户头像，并且使保存按钮不可点
      bttDisabled.value = true;
      tempURL.value = userInfo.value.profile_picture_url;
      return;
    };

    return {
      svg,
      lang,
      bttDisabled,
      userInfo,
      visible,
      loading,
      tempURL,
      errorAppend,
      errorText,
      errImg,
      handleClose,
      save,
      upload,
      changeFile,
      dragMore,
    };
  },
});
