import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MENU = _resolveComponent("MENU")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_menu, {
      id: "menu",
      "unique-opened": true,
      ref: "menuRef",
      onOpen: _ctx.menuOpened,
      onClose: _ctx.menuClosed
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MENU, { menuList: _ctx.getMenuList }, null, 8, ["menuList"])
      ]),
      _: 1
    }, 8, ["onOpen", "onClose"])
  ]))
}