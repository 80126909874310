
import {
  computed,
  defineComponent,
  nextTick,
  onUpdated,
  reactive,
  watch,
  ref,
  onMounted,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { getQueryParam } from "@/utils/format";
import { useI18n } from "vue-i18n";
import { gaEvent } from "@/utils/format";
import emitter from "@/utils/eventBus";
import { useListenBack } from "@/components/common/hooks";
import Tooltip from "@/components/common/tooltip.vue";
// @ts-ignore
import iphone from '@/assets/img/iphone.svg';
// @ts-ignore
import Launcher from '@/assets/img/Launcher.svg';
// @ts-ignore
import grayIphone from '@/assets/img/grayIphone.svg';
// @ts-ignore
import unLauncher from '@/assets/img/unLauncher.svg';
import cloneDeep from 'lodash/cloneDeep';

export default defineComponent({
  name: "my-nav",
  components: {
    Tooltip
  },
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props: any) {
    const { t } = useI18n();
    const store = useStore();
    // const setMenuList = computed(() => props.menuList);
    const defaultMenu = computed(() => store.state.defaultMenu);
    const isDemo = computed(() => store.state.isDemo);
    const expired = computed(() => store.state.expired);

    // 该ref对象用于控制没有权限是的菜单项的展开与关闭
    const noMenuRef = ref();

    const lang = computed(() => store.state.currentLang);

    // 记录是否点击了gotopay
    const clickGoPay = computed(() => store.state.clickGoPay);
    // 过期弹窗显示的过期时间
    const expiredDate = computed(() => store.state.isExpired);

    // clickedArray用于保存过期设备是否点击过
    const clickedArray = computed(() => store.state.clickedArray);

    const subscripts = computed(() => {
      return store.state.subscripts;
    });
    const haveExpiresTime = computed(() => {
      return store.state.subscripts?.expires_at;
    });
    const isSidebarNavCollapse = computed(
      () => store.getters.getIsSidebarNavCollapse
    );
    const currentMenu = computed(() => store.state.currentMenu);

    const currentDeviceInfo = computed(() => store.state.currentDeviceInfo);
    // 控制选择当个设备时，为绑定设备要显示灰色图片
    const showUnbound = computed(() => {
      return currentDeviceInfo.value.name == "leftTab.unboundName";
    });

    const pickId = ref(currentDeviceInfo.value.id);
    const cuurentDeviceId = computed(() => store.state.currentDeviceId);
    watch(cuurentDeviceId, (newV, oldV) => {
      pickId.value = newV;
    });

    const currDefaultMenu = computed(() => store.state.defaultMenu);
    watch(currDefaultMenu, (newV, oldV) => {
      console.log('currDefaultMenu', newV);
      nextTick(() => {
        if (newV == 'home') {
          filterMenu.value.forEach((item: any) => {
            item.active = false
            if (item.children) {
              item.children.forEach((sItem: any) => {
                sItem.active = false
              })
            }
          })
        } else {
          filterMenu.value.forEach((item: any) => {
            // item.active = false
            if (item.children) {
              item.children.forEach((sItem: any) => {
                sItem.active = false
                if (sItem.name == newV) {
                  item.active = true
                  sItem.active = true
                }
              })
            }
          })
        }
      })
    }, {
      // 为了刷新的时候能够自动打开当前路由的下拉菜单
      immediate: true
    });

    let state = reactive({
      // menuList: props.menuList,
      deviceInfo: computed(() => store.state.deviceInfo),
      singleDeviceShow: computed(() => store.state.singleDeviceShow),
      subscripts: computed(() => store.state.subscripts),
    });

    const deviceInfo = computed(() => store.state.deviceInfo);
    const currentAppleId = computed(() => store.state.currentAppleId);

    // alert(deviceInfo.value.length)

    let router = useRouter();
    let route = useRoute();

    useListenBack();

    // 监听菜单是展开还是关闭
    const menuOpened = (index: any) => {
      if (index === "Device") {
        store.commit("setSingleDeviceShow", false);
      }
    };
    const menuClosed = (index: any) => {
      if (index === "Device") {
        store.commit("setSingleDeviceShow", true);
      }
    };

    // let defaultActive = ref('home')
    // 点击二级菜单的跳转
    let gotoRoute = async (name: any, meta?: any) => {
      console.log('meta', meta)
      console.log('name:', name, deviceType.value, meta.isAndorid, window.location.href)
      pickId.value = meta.id;
      if ((deviceType.value == 0 && !meta.isAndorid) || (deviceType.value == 1 && meta.isAndorid)) {
        router.push('home');
      }
      if (currDefaultMenu.value == 'iosLogin' && name == 'home') {
        router.push('home');
      }
      store.commit("setDeviceType", meta.isAndorid ? 0 : 1);
      if (name == "guide" || name == "iosLogin") {
        store.commit("setCurrentDeviceId", meta.id);
        store.commit("setCurrentDeviceInfo", {
          name: meta.name,
          id: meta.id,
          model: meta.model,
        });
        store.commit("setDefaultMenu", name);
        store.commit('setIsShowBack', false)
        store.commit('setIsExpiredLogin', false)
      } else {
        store.commit("setCurrentDeviceId", meta.id);
        if (meta.isAndorid) {
          console.log('menu getCurrentDeviceInfo');

          await store.dispatch("getCurrentDeviceInfo");
          store.commit('setCurrentAppleId', "")
        } else {
          store.commit('setCurrentAppleId', meta.apple_id)
          store.commit("setCurrentDeviceId", meta.id);
          await store.dispatch("getDeviceInfo")
          await store.dispatch("getCurrentIcloudInfo");
        }

        // console.log('deviceType.value', deviceType.value, meta.isAndorid);

      }
      setTimeout(() => {
        emitter.emit("closeMenu");
      });
      store.commit("setBoundImg", meta.isEmpty);
      store.commit("setClickGoPay", false);
      // 当当前页面处在chat页面时，在已绑定设备之间切换功能项不做改变
      if (name == "guide") {
        store.commit("setDefaultMenu", name);
        // if (deviceType.value == 1 && !meta.isAndorid) {
        //   router.push('home');
        // }
        router.push({ name });
      }

      if (name == "iosLogin") {
        store.commit("setDefaultMenu", name);
        router.push({ name });
      }

      if (name == "home" && (window.location.href.includes("home") || window.location.href.includes("guide") ||
        window.location.href.includes("addDevice"))) {
        store.commit("setDefaultMenu", name);
        router.push({ name });
      }
    };

    // 点击固定的一级菜单跳转
    let gotoMyRoute = (name: string, query: any = {}) => {
      // 将新增菜单都变为不选中状态
      filterMenu.value.forEach((fItem: any) => {
        fItem.active = false
        if (fItem.children) {
          fItem.children.forEach((sItem: any) => {
            sItem.active = false
          })
        }
      });

      store.commit("setActiveTabName", "first");
      // 点击一级菜单不跳转并且弹窗
      if (
        (store.state.subscripts.expires_at &&
          store.state.deviceInfo.length == 0) ||
        window.location.href.includes("guide")
      ) {
        ElMessage({
          message: t("leftTab.bindRemind"),
          type: "error",
        });
        return;
      }

      if (
        (store.state.subscripts.expires_at &&
          store.state.deviceInfo.length == 0) ||
        window.location.href.includes("iosLogin")
      ) {
        ElMessage({
          message: t("famikeep210.The current iCloud has expired"),
          type: "error",
        });
        return;
      }

      // 登录未购买和登录已购买没有未绑定设备权限
      if (
        (!store.state.subscripts.expires_at &&
          window.location.href.includes("addDevice")) ||
        (window.location.href.includes("addDevice") &&
          store.state.subscripts.effective_device_num == 0)
      ) {
        ElMessage({
          message: t("leftTab.payRemind"),
          type: "error",
        });
        return;
      }
      // 登录已购买有未绑定设备权限
      if (
        window.location.href.includes("addDevice") &&
        store.state.subscripts.effective_device_num
      ) {
        ElMessage({
          message: t("leftTab.bindRemind"),
          type: "error",
        });
        return;
      }
      if (!store.state.subscripts.expires_at) {
        router.push({ path: "/addDevice" });
      } else {
        // router.push({ name, query })
        store.commit("setWhatsappFrom", query.from);
        store.commit("setAppFrom", query.from);
        router.push({ name });

        store.commit("setDefaultMenu", name);
      }
    };

    // 点击二级菜单的跳转
    let secondToRoute = (name: string, query: any = {}) => {
      if (!store.state.subscripts.expires_at) {
        router.push({ path: "/addDevice" });
      } else {
        store.commit("setWhatsappFrom", query.from);
        store.commit("setAppFrom", query.from);
        router.push({ name });
        store.commit("setDefaultMenu", name);
      }
    };

    // 扩大热区的点击范围，点击单个设备时，菜单也会展开
    const openMenu = () => {
      emitter.emit("openMenu");
    };

    // 去购买
    let gotoPay = () => {
      pickId.value = null;
      store.commit("setPayFrom", 1);
      gaEvent("Buy_Page", {
        event_category: "Buy",
        event_label: "From_Adddevice",
      });
      store.commit("setClickGoPay", true);
      store.commit("setDefaultMenu", null);
      // 去掉上一个选中的打勾的标记
      store.commit("setCurrentDeviceId", 0);
      store.commit("setCurrentDeviceInfo", {});
      emitter.emit("closeMenu");
      router.push({ path: "addDevice" });
    };
    const singlegotoPay = () => {
      store.commit("setPayFrom", 1);
      gaEvent("Buy_Page", {
        event_category: "Buy",
        event_label: "From_Adddevice",
      });
      store.commit("setClickGoPay", true);
      store.commit("setDefaultMenu", null);
      // 去掉上一个选中的打勾的标记
      store.commit("setCurrentDeviceInfo", {});
      store.commit("setCurrentDeviceId", 0);
      emitter.emit("closeMenu");
      router.push({ path: "addDevice" });
      openMenu();
    };
    const noGotoPay = () => {
      store.commit("setPayFrom", 1);
      gaEvent("Buy_Page", {
        event_category: "Buy",
        event_label: "From_Adddevice",
      });
      store.commit("setClickGoPay", true);
      store.commit("setDefaultMenu", null);
      router.push({ path: "addDevice" });
      noMenuRef.value?.close("Device");
      store.commit("setSingleDeviceShow", true);
    };

    const noSinglePay = () => {
      store.commit("setPayFrom", 1);
      gaEvent("Buy_Page", {
        event_category: "Buy",
        event_label: "From_Adddevice",
      });
      store.commit("setClickGoPay", true);
      store.commit("setDefaultMenu", null);
      router.push({ path: "addDevice" });
      noMenuRef.value?.open("Device");
      store.commit("setSingleDeviceShow", false);
    };

    // 菜单
    const fixMenuList = ref([
      {
        active: false,
        belong: 'android',
        className: computed(() => t("famikeep200['Location Tracking']")),
        key: "LocationTracking",
        icon: require("../img/location.svg"),
        activeIcon: require("../img/active_location.svg"),
        children: [
          { id: '1', name: "locations", belong: 'android', className: computed(() => t("famikeep200['Geolocation']")), active: false, path: "/locations" },
          { id: '2', name: "geoFencing", belong: 'android', className: computed(() => t("famikeep200['Geofence']")), active: false, path: "/geoFencing" },
        ],
      },
      {
        active: false,
        belong: 'all',
        className: computed(() => t("famikeep200['General Application']")),
        key: "GeneralApplication",
        icon: require("../img/general.svg"),
        activeIcon: require("../img/active_general.svg"),
        children: [
          { id: '1', name: "messages", belong: 'android', className: computed(() => t("famikeep200['Messages']")), active: false, path: "/messages" },
          { id: '2', name: "calllogs", belong: 'android', className: computed(() => t("famikeep200['Call history']")), active: false, path: "/calllogs" },
          { id: '3', name: "contacts", belong: 'all', className: computed(() => t("famikeep200['Contacts']")), active: false, path: "/contacts" },
          { id: '4', name: "photos", belong: 'all', className: computed(() => t("famikeep200['Photos']")), active: false, path: "/photos" },
          { id: '5', name: "calender", belong: 'android', className: computed(() => t("famikeep200['Calendar']")), active: false, path: "/calender" },
          { id: '6', name: "locations", belong: 'ios', className: computed(() => t("famikeep200['Geolocation']")), active: false, path: "/locations" },
        ],
      },
      {
        active: false,
        belong: 'android',
        className: computed(() => t("famikeep200['App Activites Tracking']")),
        key: "AppActivitesTracking",
        icon: require("../img/app_activites.svg"),
        activeIcon: require("../img/active_app_activites.svg"),
        children: [
          { id: '1', name: "installedApps", belong: 'android', className: computed(() => t("famikeep200['Installed applications']")), active: false, path: "/installedApps" },
          { id: '2', name: "appBlock", belong: 'android', className: computed(() => t("famikeep200['Disable applications']")), active: false, path: "/appBlock" },
        ],
      },
      {
        active: false,
        belong: 'android',
        className: computed(() => t("famikeep200['Screen Time Tracking']")),
        key: "ScreenTimeTracking",
        icon: require("../img/screen_time.svg"),
        activeIcon: require("../img/active_screen_time.svg"),
        children: [
          { id: '1', name: "screenTime", belong: 'android', className: computed(() => t("famikeep200['Screen usage time']")), active: false, path: "/screenTime" },
          { id: '2', name: "screenBlock", belong: 'android', className: computed(() => t("famikeep200['Screen disable']")), active: false, path: "/screenBlock" },
        ],
      },
      {
        active: false,
        belong: 'android',
        className: computed(() => t("famikeep200['Social Application']")),
        key: "SocialApplication",
        icon: require("../img/socail.svg"),
        activeIcon: require("../img/active_socail.svg"),
        children: [
          { id: '1', name: "whatsapp", belong: 'android', className: computed(() => t("leftTab['whatsapp']")), active: false, path: "/hitpaw/HitpawDowned" },
          { id: '2', name: "instagram", belong: 'android', className: computed(() => t("leftTab['instagram']")), active: false, path: "/hitpaw/Natrual" },
        ],
      }
    ])

    // 一级菜单的点击
    const clickTitle = (item: any, index: number) => {
      // store.commit("setDefaultMenu", null);
      // 点击一级菜单不跳转并且弹窗
      if (
        (store.state.subscripts.expires_at &&
          store.state.deviceInfo.length == 0) ||
        window.location.href.includes("guide")
      ) {
        ElMessage({
          message: t("leftTab.bindRemind"),
          type: "error",
        });
        return;
      }

      if (
        (store.state.subscripts.expires_at &&
          store.state.deviceInfo.length == 0) ||
        window.location.href.includes("iosLogin")
      ) {
        ElMessage({
          message: t("famikeep210.The current iCloud has expired"),
          type: "error",
        });
        return;
      }

      // 登录未购买和登录已购买没有未绑定设备权限
      if (
        (!store.state.subscripts.expires_at &&
          window.location.href.includes("addDevice")) ||
        (window.location.href.includes("addDevice") &&
          store.state.subscripts.effective_device_num == 0)
      ) {
        ElMessage({
          message: t("leftTab.payRemind"),
          type: "error",
        });
        return;
      }
      // 登录已购买有未绑定设备权限
      if (
        window.location.href.includes("addDevice") &&
        store.state.subscripts.effective_device_num
      ) {
        ElMessage({
          message: t("leftTab.bindRemind"),
          type: "error",
        });
        return;
      }

      if (item.active) {
        (filterMenu.value[index] as any).active = false
      } else {
        // filterMenu.value.forEach((fItem:any) => {
        //   fItem.active = false
        // });
        (filterMenu.value[index] as any).active = true
      }
    }

    // 二级菜单的点击
    const clickSub = (father: any, son: any, fIndex: number, sIndex: number) => {
      // 高亮当前二级菜单，并且是其他二级菜单变为不选中状态
      filterMenu.value.forEach((fItem: any) => {
        if (fItem.children) {
          fItem.children.forEach((sItem: any) => {
            sItem.active = false
          })
        }
      });
      (filterMenu.value[fIndex] as any).children[sIndex].active = true

      if (son.name == 'whatsapp' || son.name == 'instagram') {
        store.commit("setDefaultMenu", son.name);
        secondToRoute(son.name, { from: 'Leftlist' })
      } else {
        secondToRoute(son.name)
      }

      // setTimeout(() => {
      //   store.state.deviceType = !store.state.deviceType
      //   console.log('------', store.state.deviceType);
      // }, 5000);
    }

    // 用当前设备类型来决定具体的menu
    const deviceType = computed(() => store.state.deviceType)
    const filterMenu = ref([])

    watch(deviceType, (newV) => {
      console.log('newV::', newV);
      // ios的情况
      if (newV) {
        let tempArr = cloneDeep(fixMenuList.value)
        const newArr: any = tempArr.filter((item) => {
          if (item.belong == 'ios' || item.belong == 'all') {
            item.children = item.children.filter((childItem) => {
              return childItem.belong === 'ios' || childItem.belong === 'all'
            }
            )
            return true; // 返回true表示该menuItem满足条件，将包含在新数组中
          }
          return false
        })

        filterMenu.value = newArr
      } else {
        let tempArr = cloneDeep(fixMenuList.value)
        const newArr: any = tempArr.filter((item) => {
          if (item.belong == 'android' || item.belong == 'all') {
            item.children = item.children.filter((childItem) => childItem.belong === 'android' || childItem.belong === 'all');
            return true; // 返回true表示该menuItem满足条件，将包含在新数组中
          }
          return false
        })

        filterMenu.value = newArr
      }
      console.log('filterMenu.value', filterMenu.value);

    }, {
      immediate: true,
    })

    return {
      pickId,
      expiredDate,
      deviceInfo,
      lang,
      noGotoPay,
      noSinglePay,
      isDemo,
      state,
      noMenuRef,
      openMenu,
      defaultMenu,
      subscripts,
      showUnbound,
      haveExpiresTime,
      gotoRoute,
      gotoMyRoute,
      menuOpened,
      menuClosed,
      gotoPay,
      singlegotoPay,
      expired,
      currentDeviceInfo,
      clickGoPay,
      isSidebarNavCollapse,

      clickTitle,
      fixMenuList,
      clickSub,
      secondToRoute,

      // 
      filterMenu,
      deviceType,
      grayIphone,
      unLauncher,
      iphone,
      Launcher,
      currentAppleId
    };
  },
});
